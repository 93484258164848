<template>
  <div class="page-header-box">
    <!-- <v-row>
      <div class="main-sec">
        <div class="btn-to-bottom" @click="toBottom"></div>
        <div class="main-sec-text">
          <h1 class="main-sec-title">Connect and network!</h1>
          <h5 class="main-sec-subtitle">Here comes the business networking platform that you've been waiting for</h5>
          <v-btn class="main-sec-btn" v-if="!(this.$store.getters.isLoggedIn)" @click="toLoggin">Log in here</v-btn>
        </div>
      </div>
    </v-row> -->
    <v-toolbar dense class="bg-header-page pt-0 mt-0" height="200" flat>
      <div class="btn-to-bottom" @click="toBottom"></div>
      <v-toolbar-title class="mx-auto text-center">
        <!-- <span class="white--text text-center">Welcome</span> -->
        <h1><span class="white--text">{{ header.titlepage }}</span></h1>
        <v-layout row class="text-center my-4">
          <!-- <v-flex>
            <v-btn outlined small fab color="white" class="outine-2">
              <v-icon>{{ header.icon }}</v-icon>
            </v-btn>
          </v-flex> -->
        </v-layout>
      </v-toolbar-title>
    </v-toolbar>

  </div>
</template>

<script>
import { mdiDomain, mdiAccountNetwork } from '@mdi/js';
export default {
  props: {
    header: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      links: [
        {
          title: 'Colaborators',
          description: 'Follow and colaborate with companies and professionnals',
          color: 'secondary'
        },
        {
          title: 'Proposals',
          description: 'Send offers to specific users',
          color: 'blue'
        },
        {
          title: 'Services',
          description: 'Browse all services and products offered by companies',
          color: 'teal'
        },
        {
          title: 'Vacancies',
          description: 'Search and browse recent job opportunities',
          color: 'primary'
        },
        {
          title: 'Blog',
          description: 'Communicate effectively by publishing products and reviews for businesses and professionals.',
          color: 'primary'
        },

        {
          title: 'Chat',
          description: 'Communicate in real time with your professionals and companies network.',
          color: 'primary'
        },
      ],
      icons: { mdiDomain, mdiAccountNetwork }
    }
  },
  methods: {
    toBottom(){}
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/header-page-style.scss';

.bg-header-page {
  background: linear-gradient(rgba(48, 121, 190, 0.08), rgba(114, 169, 86, 0.08)), url('~@/assets/images/bgs/person-docs.png') center center / cover no-repeat;
}
</style>
