<template>
  <div class="">
    <header-page :header="headers"></header-page>
    <v-container>
      <v-row>
        <v-col v-if="loaderdata === false" class="pa-4">
          <v-card tile flat class="px-4">
            <v-card-title>
              <v-icon @click="backToPrevious" class="mr-10" color="secondary">{{ icons.mdiKeyboardBackspace }}</v-icon>
              <v-spacer></v-spacer>
              <v-card-subtitle class="text-md pa-0">
                <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon>
                <span class="secondary--text">Published at: {{ ext_proposals.created_at }}</span>
              </v-card-subtitle>
            </v-card-title>
            <v-card-title>
              <span>{{ ext_proposals.title }}</span>
            </v-card-title>
            <v-card-subtitle>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiTargetAccount }}</v-icon>
                <span>For: </span>
                <span> {{ ext_proposals.type }}</span>
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiScatterPlot }}</v-icon>
                <span>Domain: </span>
                <span>{{ ext_proposals.domain }}</span>
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
                <span>Location: </span>
                <span>{{ ext_proposals.location }}</span>
              </div>
            </v-card-subtitle>
            <v-card-text class="d-flex align-center">
              <v-avatar size="45" class="primary mr-4" tile>
              </v-avatar>
              <div class="mx-4 d-bloc">
                <h3>
                  <span class="primary--text">
                    {{ ext_proposals.enterprise }}
                  </span>
                </h3>
                <!-- <a :href="ext_proposals.website" target="_blank">{{ ext_proposals.website }}</a> -->
              </div>
              <v-spacer></v-spacer>
              <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
              <span class="error--text">Expiring on: {{ ext_proposals.expire_at }}</span>
            </v-card-text>
            <v-card-text>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <check-role-dialog
                :actions="{ type: reoi.enterprise.type.libele, actionText: 'Reply proposal', link: 'proposal.reply', role: 'rfp_reply', title: reoi.title, k: reoi.id }">
              </check-role-dialog> -->
              <v-btn class="secondary" @click="openTab(ext_proposals.url)">
                <v-icon small class="mr-2">{{ icons.mdiOpenInNew }} </v-icon> Apply
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card tile class="mt-4 px-6">
            <v-divider class="mt-4 mb-6"></v-divider>
            <v-card-title class="subaccent">{{ ext_proposals.title }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text></v-card-text>
            <v-card-text class="text-justify" v-html="ext_proposals.content">
            </v-card-text>
            <v-card-text cols="12">
              <v-card-subtitle class="subaccent">
              </v-card-subtitle>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-else>
          <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import { mdiAlertCircle, mdiEyeOffOutline, mdiDomain, mdiTargetAccount, mdiCloseCircle, mdiCalendar, mdiKeyboardBackspace, mdiScatterPlot, mdiAlertOutline, mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import DetailAboutCompany from "@/views/components/company/DetailAboutCompany.vue"
import { isNull } from 'url/util';


export default {
  components: {
    CheckRoleDialog,
    HeaderPage,
    DetailAboutCompany
  },
  data() {
    return {
      loaderdata: false,
      submit: true,
      absolute: true,
      overlay: false,
      ext_proposals: {},
      headers: {
        titlepage: '',
        icon: this.icons.mdiDomain
      },

      loadingRfpEnterprise: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  computed: {
    length() {
      return 7000
    },
  },
  methods: {
    backToPrevious() {
      this.$router.go(-1)
    },
    openTab(link_ref) {
      window.open(link_ref, '_blank');
    },
    replaceSharp(value) {
      if ((value === null) || (value === "#")) {
        return this.icons.mdiEyeOffOutline
      }
      else {
        return value
      }
    },
    loadInitialProposals() {
      this.loaderdata = true
      // Drequest.api(`external.onceproposal?base=${}&title=${this.$route.params.offer_id}`)
      this.loaderdata = true
      Drequest.api(`external.onceproposal`)
        .data({
          "onceproposal": {
            "keyword": this.$route.params.keyword,
            "link": this.$route.params.offer_title
          }
        })
        .raw((response) => {
          if (response.success === true) {
            this.ext_proposals = response.data
            this.headers.titlepage = response.data.title
            this.loaderdata = false
          }
          else {
            this.loaderdata = false
          }
        })
        .catch((err) => {
          this.loaderdata = false
          this.loaderdata = false
        });
    },

  },
  beforeMount() {
    this.loadInitialProposals()
  },
  setup(props) {
    const icons = { mdiEyeOffOutline, mdiAlertCircle, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiDomain, mdiTargetAccount, mdiScatterPlot, mdiCloseCircle, mdiMapMarkerOutline, mdiOpenInNew }

    return {
      icons
    }
  }
}
</script>